.cart {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 30%;
  max-width: 50%;
  max-height: 85%;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: auto;
  padding: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 0.5rem;
  z-index: 10;
  backdrop-filter: blur(20px);
}

.cart h2 {
  font-size: 1.5rem;
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
  margin: 1rem 0;
}

.cart .close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}

.cart .close:hover {
  text-decoration: underline;
}

.cart-closed {
  position: fixed;
  top: 0%;
  right: 1%;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.25rem;
  width: 50px;
  height: 50px;
  z-index: 10;
}

.cart-closed:hover {
  opacity: 0.9;
}

.cart img {
  width: 70px;
  margin-right: 1rem;
}

.cart input {
  width: 50px;
  padding: 0;
  margin: 0 0.5rem 1rem 0;
}

.formInput {
  min-width: 200px;
  padding: 0;
  margin: 0 0.5rem 1rem 0;
}

@media screen and (max-width: 768px) {
  .cart {
    position: relative;
    max-width: 100%;
    max-height: auto;
    box-shadow: none;
  }

  .cart-closed {
    position: relative;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.128);
    opacity: 0.8;
    z-index: 9;
  }
}
