@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Maven+Pro&family=Montserrat:wght@100;200;300&family=Nunito+Sans:wght@200;300&family=Roboto+Flex:opsz@8..144&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
:root {
  --primary: #ffffff;
  --secondary: rgb(100, 100, 100);
  --tertiary: #565656;
  --light: #7f7f7f;
  --dark: #444444;
  --error: rgba(228, 46, 1, 0.792);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --custom-modal: rgba(15, 17, 17, 0.928);
}

* {
  box-sizing: border-box;
  color: rgb(252, 252, 252);
}

html,
body {
  width: 100vw;
  background-image: url("../public/images/paradise55.png");
  background-attachment: fixed;
  background-size: cover;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: var(--secondary);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.9rem;
}

h6 {
  font-size: 0.7rem;
}

p {
  font-size: 0.9rem;
  margin-top: 0;
}

ul {
  list-style: none;
}

a {
  color: var(--tertiary);
  text-decoration: none;
}

header {
  position: fixed;
  width: 100vw;
  top: 0;
  height: 60px;
  z-index: 1;
}

canvas {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  outline: none;
  mix-blend-mode: exclusion !important;
}

.container2 {
  height: 100vh;
  display: grid;
  place-items: center;
}

.container2 h1 {
  color: white;
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 900;
}

.landingP {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: 100px;
}

.landingBtn {
  height: 40px;
  box-shadow: 1px 1px 15px var(--secondary);
}

.box-shadow-custom {
  box-shadow: 0 0 1rem rgba(225, 225, 225, 0.214);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  color: black;
}

button:hover {
  opacity: 0.8;
}

option {
  color: var(--dark);
}

button[disabled] {
  cursor: default;
  opacity: 0.8;
}

img {
  max-width: 100%;
  border-radius: 10px;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

.borderwrap {
  box-shadow: 0 0 1rem rgba(225, 225, 225, 0.214);
  border-radius: 5px;
  display: grid;
  place-items: center;
  margin-top: 25px;
  max-width: 800px;
}

.unverifiedbar {
  z-index: 1;
  margin-top: 54px;
  background-color: rgba(249, 30, 30, 0.381);
  text-align: center;
  color: rgb(255, 255, 255);
  width: 100vw;
  position: fixed;
}

.pendingreviewbar {
  z-index: 1;
  margin-top: 54px;
  background-color: rgba(247, 180, 73, 0.613);
  text-align: center;
  color: rgb(255, 255, 255);
  width: 100vw;
  position: fixed;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--light);
  outline: none;
  height: 40px;
  border-radius: 3px;
  transition: box-shadow 0.2s;
  color: var(--dark);
  background-color: #ffffff;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
}

select {
  font-size: 1rem;
  padding: 0.25rem;
  width: 176px;
  height: 40px;
  color: var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

textarea {
  font-family: "Nunito Sans", sans-serif;
  color: var(--dark);
}

.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.center {
  position: absolute;
  top: 20%;
  left: 48%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  text-align: center;
}

.error-text {
  color: var(--error);
}

.custom-backdrop-blur {
  backdrop-filter: blur(20px);
}

/* down arrow  */

.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 10px;
  display: block;
  text-align: center;
  font-size: 20px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0;
  width: 13px;
  height: 13px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  z-index: 9;
  left: 50%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 4s ease-in-out infinite;
  -moz-animation: fade_move_down 4s ease-in-out infinite;
  animation: fade_move_down 4s ease-in-out infinite;
}

@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
  0% {
    -moz-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}

/* loading spinner  */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  z-index: 5;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 

font-family: 'Dancing Script', cursive;

font-family: 'Maven Pro', sans-serif;
font-family: 'Roboto Flex', sans-serif;


font-family: 'Montserrat', sans-serif;
font-family: 'Nunito Sans', sans-serif;

*/
